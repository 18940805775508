/* Familias.css */
.familias-container, .productos-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px;
  display: flex;
  justify-content: center;
}

.familia-card {
  width: 8%;
  margin: 10px;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  cursor: pointer;
  transition: transform 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.producto-card {
  width: 20%;
  margin: 10px;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transition: transform 0.3s ease;
  display: flex;
  justify-content: space-between;
}

.familia-card:hover, .producto-card:hover {
  transform: scale(1.05);
}

.familia-card.hamburguesas, .producto-card.hamburguesas {
  border: 2px solid rgba(250, 210, 225, 0.5); /* Color pastel suave */
}

.familia-card.raciones, .producto-card.raciones {
  border: 2px solid rgba(197, 225, 165, 0.5);
}

.familia-card.bocadillos, .producto-card.bocadillos {
  border: 2px solid rgba(255, 171, 145, 0.5);
}

.familia-card.sandwich, .producto-card.sandwich {
  border: 2px solid rgba(129, 222, 234, 0.5);
}

/* Nuevas Familias */
.familia-card.sartenes, .producto-card.sartenes {
  border: 2px solid rgba(255, 218, 185, 0.5); /* Color pastel suave */
}

.familia-card.tradicionales, .producto-card.tradicionales {
  border: 2px solid rgba(220, 220, 220, 0.5); /* Color pastel suave */
}

.familia-card.ibericos, .producto-card.ibericos {
  border: 2px solid rgba(244, 164, 96, 0.5); /* Color pastel suave */
}

.familia-card.mar, .producto-card.mar {
  border: 2px solid rgba(173, 216, 230, 0.5); /* Color pastel suave */
}

.familia-card.tierra, .producto-card.tierra {
  border: 2px solid rgba(152, 251, 152, 0.5); /* Color pastel suave */
}

.familia-card.bebidas, .producto-card.bebidas {
  border: 2px solid rgba(255, 218, 185, 0.5); /* Color pastel suave */
}
/* Estilos para títulos y párrafos */
.familia-card h2, .producto-card h2 {
  color: #333;
  font-size: 18px;
  margin-bottom: 10px;
}

.familia-card p, .producto-card p {
  color: #666;
  font-size: 16px;
}

/* Ajustes para la barra de búsqueda y paginación */
.search-bar {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.search-bar input {
  width: 80%;
  padding: 10px;
  font-size: 16px;
  border: 2px solid #ccc;
  border-radius: 10px;
}


.pagination {
  display: flex;
  justify-content: center;
  margin: 20px;
}

.pagination button {
  margin: 0 10px;
  font-size: 16px;
  background: none;
  border: 2px solid #ccc;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.botonAgregar {
  margin-top: 10px;
  padding: 10px 20px;
  border: none;
  background-color: #5cb85c;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.botonAgregar:hover {
  background-color: #4cae4c;
}

.cuadroDialogo {
width: 400px;
}

/* Media queries para adaptación móvil */
@media (max-width: 768px) {
  .familia-card, .producto-card{
    width: 30% ; /* 2 elementos por fila en dispositivos más pequeños */
  }

  .search-bar input {
    width: 90%;
  }
 .cuadroDialogo {
  width: 250px
 }

 .selectClientes {
  width: 80%
 }
}


@media (min-width: 769px) and (max-width: 1024px) {
  .familia-card {
    width: 15%;
    margin: 10px;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    transition: transform 0.3s ease;
    display: flex;
    justify-content: space-between;
  }
}
