/* Ajustes.css */
.ajustes-container {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

/* Estilos para los acordeones */
.accordion-root {
  margin-bottom: 10px; /* Espacio entre acordeones */
}

/* Estilos para las cabeceras de los acordeones */
.accordion-summary {
  background-color: #f7f7f7; /* Fondo claro para las cabeceras */
  border-bottom: 1px solid #e0e0e0; /* Línea sutil entre cabecera y contenido */
}

.accordion-details {
  padding: 10px;
}

/* Estilos para las tablas */
.table-root {
  width: 100%; /* Asegura que la tabla use todo el espacio disponible */
  overflow-x: auto; /* Permite desplazamiento horizontal en dispositivos pequeños */
}

.table-cell, .table-head {
  font-size: 0.875rem; /* Tamaño de fuente adecuado para legibilidad en dispositivos */
}

/* Botones y tipografía general */
.button-error {
  color: white;
  background-color: #f44336; /* Rojo para acciones de eliminar */
  margin-right: 5px;
}

.button-error:hover {
  background-color: #d32f2f;
}

.typography-header {
  padding: 10px 0;
  font-weight: bold;
}

/* Paginación */
.pagination-root {
  display: flex;
  justify-content: flex-end; /* Alinea la paginación a la derecha */
  padding: 10px 0;
}

/* Responsividad */
@media (max-width: 768px) {
  .ajustes-container {
    padding: 10px;
  }

  .accordion-details {
    padding: 5px;
  }

  .table-cell, .table-head {
    font-size: 0.75rem; /* Más pequeño en dispositivos móviles */
  }
}
