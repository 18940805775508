/* Navbar.css */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    padding: 10px 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.navbar-logo img {
    max-width: 100px;
}

.navbar-selector {
    /* Estilos del selector */
}

.navbar-icons {
    display: flex;
}

.navbar-icons-left {
    margin-right: auto;
}

.navbar-icons-right {
    margin-left: auto;
    margin-right: 2rem;
}

/* Estilos de los iconos */
.navbar-icons-right .icon {
    font-size: 24px; /* Tamaño de los iconos */
    cursor: pointer; /* Cambia el cursor a pointer */
    transition: transform 0.3s ease; /* Agrega una transición suave al efecto hover */
}

/* Efecto de hover */
.navbar-icons-right .icon:hover {
    transform: scale(1.1); /* Hace que los iconos se agranden un poco al pasar el ratón por encima */
}


@media screen and (max-width: 768px) {
    .navbar {
        flex-direction: column;
        padding: 10px;
    }
    
    .navbar-icons-right {
        margin-left: auto;
        margin-right: auto; /* Center icons horizontally */
        margin-top: 10px; /* Add margin at the top */
    }
}