/* Cart.css */
.cart-container {
  position: fixed;
  right: 0;
  top: 0;
  width: 300px;
  height: 100%;
  background: #fff;
  border-left: 1px solid #ccc;
  padding: 20px;
  transform: translateX(100%);
  transition: transform 0.3s ease;
  z-index: 1000;
}

.notes-input.large-notes {
  width: 100%; /* Ajusta el ancho del textarea */
  height: 100px; /* Ajusta la altura del textarea */
  padding: 10px; /* Agrega un poco de padding para mayor espacio interior */
  font-size: 16px; /* Ajusta el tamaño de la fuente si es necesario */
  border-radius: 5px; /* Añade bordes redondeados si es necesario */
  border: 1px solid #ccc; /* Añade un borde */
  resize: vertical; /* Permite que el usuario cambie el tamaño verticalmente */
  box-sizing: border-box; /* Asegura que el padding y el borde se incluyan en el ancho total */
}


.cart-container.expanded {
  transform: translateX(0);
}
  
  .cart-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .item-info {
    flex-grow: 1;
  }
  
  .item-controls {
    display: flex;
    align-items: center;
  }
  
  .item-controls button {
    margin: 0 5px;
    background: #f0f0f0;
    border: none;
    cursor: pointer;
    padding: 5px;
    border-radius: 5px;
    font-size: 20px;
  }

  .clear-cart {
    margin: 0 5px;
    background: #f0f0f0;
    border: none;
    cursor: pointer;
    padding: 5px;
    border-radius: 5px;
    font-size: 15px;
  }
  
  .item-controls span {
    min-width: 20px;
    text-align: center;
  }
  
  .total {
    margin-top: 30px;
    font-weight: bold;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: space-between;

  }
  
  .toggle-cart {
    position: absolute;
    top: 50%;
    left: -70px; /* Ajusta para que el botón aparezca fuera del carrito */
    background: #f0f0f0;
    border: 1px solid #ccc;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 50%;
}
  

.item-count {
  position: absolute;
  top: -10px; /* Ajusta según necesidad para alinear con el botón */
  right: -10px; /* Ajusta según necesidad */
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 0.75rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}



@media (max-width: 768px) {
  .toggle-cart {
    position: absolute;
    top: 50%;
    left: -50px; /* Ajusta para que el botón aparezca fuera del carrito */
    background: #f0f0f0;
    border: 1px solid #ccc;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 50%;
}
  }
